import clientes from "../assets/clientes.png"
import marca from "../assets/marca.png"
import BotaoZap from "../components/botaozap"

const Clientes =() => {

    return(
        <div className="flex flex-col sm:flex-row bg-preto p-10 text-white items-center justify-center">
            <div>
                <img src={clientes} alt="Clientes" />
            </div>

            <div className="flex flex-col items-center justify-center">
                <img src={marca} alt="Inoovare" /> 
                <div className="flex flex-col items-center bg-cinza rounded-3xl p-10 max-w-[80%] text-2xl font-Bask">
                    <p className="text-5xl text-center">Transforme seu sorriso hoje mesmo! </p>
                    <p className="text-2xl text-center text-yellow-800 ">
                        Agende sua avaliação na Innovare e dê o primeiro passo para um sorriso saudável e radiante.
                   </p>
                   <BotaoZap texto="Fale Conosco"/>
                </div>
            </div>

        </div>
    )
}

export default Clientes
import avaliacao from "../assets/avaliacao.png"
import gg from "../assets/gg.jpg"
import choc from "../assets/choc.jpg"
import ali from "../assets/ali.jpg"
import amanda from"../assets/Amanda.jpg"
import isabela from "../assets/Isabela.jpg"
import Avalia from "../components/Avalia"

const Avaliacao = () =>{

    return(
        <div className="flex flex-col p-4 items-center justify-center bg-preto font-Bask ">
            <p className="text-prata text-5xl  text-center p-5 md:pt-8">Avaliação dos nossos clientes</p>
            <div className="flex flex-col items-center justify-center m-10 bg-cinza rounded-3xl ">
                <img src={avaliacao} alt="Avaliação exelente"/>
                <div className="flex flex-col sm:flex-row">
                    <img className="max-w-80 max-h-40 m-5" src={gg} alt="Avaliação excelente cliente Gg Frete"/>
                    <img className="max-w-80 max-h-48 m-5" src={choc} alt="Avaliação excelente cliente Chocolate Rosa"/>
                     <img className="max-w-80 max-h-80 m-5" src={ali} alt="Avaliação excelente cliente Ali"/>
                    
                </div>
                
                                
            </div>
        </div>
    )
}

export default Avaliacao
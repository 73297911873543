import { Button } from "bootstrap"
import { useState } from "react";
import BotaoZap from "./botaozap";


const Servico =({imagem, titulo, texto}) => {
    const [abrir, setAbrir] = useState(false)

    const abrirservico = () => {
        setAbrir(!abrir)
       
      };

    return(
        <>
        <div className="flex  flex-col items-center rounded-3xl m-4 mt-32 justify-center bg-cinza text-white w-80 ">
            <img className='rounded-full mt-[-90px]' src={imagem} alt={titulo} />
            <h2 className="font-Bask text-3xl">{titulo}</h2>
            
            {abrir?
            <p className="m-5 text-justify text-1xl">{texto}</p>
            :
            <button className="
            bg-[#2b2d39]
            text-white 
            rounded-2xl
            md: w-[100px]
            md: h-[40px]
            m-5
            
            shadow-md
            hover:-translate-y-1
            hover:scale-110
            hover:bg-preto duration-300
            " 
            onClick={abrirservico}>Saiba Mais</button>
            }
            {abrir?
            <button onClick={() =>  window.open("http://wa.me/5573981205668", "_black")} className="
            bg-prata
            text-white 
            rounded-xl
            md: w-[100px]
            md: h-[40px]
            m-5
            
            shadow-md
            hover:-translate-y-1
            hover:scale-110
            hover:bg-primary-focus duration-300
            ">
                Fale Conosco
            </button>
            :
            null
            }
        </div>
        </>
    )

}

export default Servico
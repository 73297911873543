import React, { useEffect, useRef, useState } from 'react';
import 'animate.css/animate.min.css';
import innovare from '../assets/innovare.png'
import BotaoZap from '../components/botaozap';

const Beneficios = () => {

    const item = useRef();
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        function handleOpenScollAnimation() {
            const rect = item.current.getBoundingClientRect();

            if (rect.y + 300 <= (window.innerHeight || document.documentElement.clientHeight)) {
                setShowAnimation(true);
                window.removeEventListener('scroll', handleOpenScollAnimation);
            }
        }

        window.addEventListener('scroll', handleOpenScollAnimation);

        // Cleanup: Remove o listener quando o componente for desmontado
        return () => {
            window.removeEventListener('scroll', handleOpenScollAnimation);
        };
    }, []);

    useEffect(() => {
        if (showAnimation) {
            const timeoutId = setTimeout(() => {
                setShowAnimation(false);
            }, 700); 

            return () => clearTimeout(timeoutId);
        }
    }, [showAnimation]);


    return(

    <section  className='md:p-12 bg-cinza ' >
        <div className="flex flex-col-reverse font-Bask sm:flex-row items-center justify-center">
           
            <div  className={`max-y-[650px] duration-1000 p-4 
                    animate__animated ${showAnimation ? 'animate-slide-Left-05' : ''}`}>
                        
                        <img ref={item} src={innovare} alt='recepção Innovare' className='rounded-1x1' />
             </div>             
          
        
            <div className={`flex flex-col m-4 items-center text-white max-w-[600px] animate__animated ${showAnimation ? 'animate-slide-Rigth-08' : ''}`}>
               <p className='text-5xl ' >
                Sobre Nós
               </p> 
               <br/>
               <h1 className='text-2xl' >
                 INNOVARE ODONTOLOGIA INTEGRADA
               </h1> 
               <br/>
               <br/>
               <p className='text-2xl text-justify' >
               Localizados em São João do Panelinha, Camacan-BA, oferecemos uma gama 
               completa de serviços odontológicos com um padrão de qualidade excepcional, 
               acessível a toda a população. Nossos profissionais capacitados garantem 
               tratamentos personalizados e cuidadosos, proporcionando sorrisos saudáveis 
               e bonitos.
               </p> 
               <br/>
               <BotaoZap texto="Fale Conosco"/>
               <br/>
            </div>    
                                  
        </div>
    </section>
    )
}

export default Beneficios
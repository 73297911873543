import { useContext, useEffect, useRef, useState } from 'react';
import 'animate.css/animate.min.css';
import  marca  from '../assets/marca.png'
import  almir  from '../assets/almir.png'
import BotaoZap from '../components/botaozap';
import { BotaoContext } from '../providers/BotaoContext';

const Promessas = () =>{

    const { showModal } = useContext(BotaoContext)

    const [animate, setAnimate] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 2000);

    // Limpe o timeout ao desmontar o componente para evitar vazamentos de memória
    return () => clearTimeout(timeout);
  }, []); // Executado apenas uma vez no carregamento do componente
   
    return(
        <header className="w-full md:h-[650px] bg-preto">
         <div className="flex flex-col md:flex-row items-center justify-end ">
            <div className="max-w-[508px] md:mr-20">
            <img className='mx-auto animate-slide-down-05' src={marca} alt='logo' />
            <br/>
            <p className="font-Bask mr-4 ml-4 text-white text-3xl font-bold text-justify  
            animate-slide-down-05">
                    Explore o conforto e a excelência em odontologia no nosso consultório, especializado em próteses, implantes e ortodontia. 

                    </p>
                              
            
            <div className="flex justify-center items-center">
              <BotaoZap texto="Cuide do seu sorriso conosco"/>
            </div>        
           
            </div>
            
            <div className={` flex justify-end max-w-[574px]  duration-1000  ${animate ? 'animate-pulse' : ''}`}>
                <img  src={almir} alt='Foto da Innovare' className="w-full max-w-[100%] h-auto "/>
                
               
            </div> 
            
       </div >

</header>
    )
}
export default Promessas
import almir2 from "../assets/almir2.png"
import landin from "../assets/landin.png"
import paulo from "../assets/paulo.png"

const Proficionais = () => {

    return(
        <> 
         <div className="flex flex-col p-4 font-Bask items-center text-center bg-cinza">
            <div>
            <p className='text-5xl text-white  ' >
                Nossos profissionais
            </p>
            </div>

            <div className="flex flex-col sm:flex-row items-start justify-center ">
                <div className="m-10 max-w-80  text-justify max-w-90">
                    <div className="bg-white flex rounded-3xl items-start justify-center" >
                        <img src={almir2} alt="Dr Almir" />
                    </div >
                    <p className="text-2xl"><strong>Dr. Almir Leite </strong></p>
                    <p className="text-sm">CRO-BA 23984</p>
                    <br/>
                    <p className="text-xl" >Odontologia geral e próteses. Experiência comprovada em restaurações, clareamento e próteses personalizadas de alta qualidade.</p>
                </div> 

                <div className="m-10 max-w-80 items-start  text-justify max-w-90">
                    <div className="bg-white flex rounded-3xl justify-center" >
                        <img src={landin} alt="Dr Almir" />
                    </div>
                    <p className="text-2xl"><strong>Dr. Gustavo Landim </strong></p>
                    <p className="text-sm">CRO-BA 14082 </p>
                    <br/>
                    <p className="text-xl">Especialista em Ortodontia, experiente com anos de atuação, dedicado a transformar sorrisos e garantir sua saúde bucal.</p>
                </div> 

                <div className="m-10 max-w-80  text-justify max-w-90">
                     <div className="bg-white  flex backdrop:after: rounded-3xl justify-center" >
                        <img src={paulo} alt="Dr Almir" />
                    </div>
                    <p className="text-2xl"><strong>Dr. Paulo Ricardo </strong></p>
                    <p className="text-sm">CRO-BA 21861 </p>
                    <br/>
                    <p className="text-xl">Especialista em Implantes Dentários e em formação para Bucomaxilofacial. Atua em cirurgias complexas, como Implantes e extração de terceiro molar, com total segurança.</p>
                </div>
                
                
            </div>  
         </div>  

        </>
    )
}

export default Proficionais
import logo from '../assets/marca.png'
import insta from '../assets/insta.png'
import local from '../assets/local.png'
import zap from '../assets/zap.png'
import email from '../assets/email.png'

const Rodape = () => {

    return (
        <>
         <div className='flex font-Bask  items-center justify-center bg-img_bg_rodape2 sm:bg-img_bg_rodape'>
               
                <div className='flex flex-col mt-5'>
                   <p className='text-prata text-5xl text-center'>
                    <strong>Agende sua Avaliação</strong> 
                   </p>
                   <br/>
                    <div onClick={() =>  window.open("http://wa.me/5573981205668", "_black")}  
                         className='flex items-center justify-left cursor-pointer rounded-full hover:bg-cinza duration-300 hover:scale-110 '>
                        <img className='isnta  text-white'  src={zap} alt='whatssap'/>
                        <p className='p-1 text-white text-2xl '>
                            (73) 98120-5668
                        </p>
                    </div>
                    <div  onClick={() =>  window.open("https://www.google.com/maps/dir/Innovare+odontologia+-+Especialitas+em+Pr%C3%B3teses+e+implantes+dent%C3%A1rios+-+Av.+Joviniano+Pinheiro+de+Moura,+329+-+S%C3%A3o+Jo%C3%A3o+do+Panelinha,+Camacan+-+BA,+45880-000/@-15.3125677,-39.4985605,13z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x7382eb963403bd5:0xd4ebec365834e1b9!2m2!1d-39.4141123!2d-15.2626657!1m5!1m1!1s0x73789be7b088467:0xbf7650ebe4b839d9!2m2!1d-39.4928119!2d-15.372871!3e0?entry=ttu", "_black")}  
                         className='flex items-center justify-left cursor-pointer rounded-full hover:bg-cinza duration-300 hover:scale-110 '>
                        <img className='isnta' src={local} alt='instagram'/>
                        <p className='p-1 text-white text-2xl max-w-80 '>
                        Avenida Joviniano Pinheiro de Moura, 329, São João do Panelinha, Camacan-BA
                            </p>
                    </div>
                    <div onClick={() =>  window.open("https://www.instagram.com/innovare.panelinha/", "_black")}
                         className='flex items-center justify-left cursor-pointer rounded-full hover:bg-cinza duration-300 hover:scale-110 '>
                        <img className='isnta' src={insta} alt='instagram'/>
                        <p  className='text-white text-2xl'>
                        innovare.panelinha
                            </p>
                    </div>
                    {/* <div className=' flex items-center justify-left'>
                        <img className='isnta' src={email} alt='email'/>
                        <p className='text-1xl text-white'>Innovare.panelinha@gmail.com</p>
                    </div> */}
                   
                </div>
            </div>
        </>
    )
}

export default Rodape
import React from 'react';
import Estrategia from '../components/estrategia';
import Servico from '../components/servico';
import clinica from '../assets/clinica.png'
import ortodon from '../assets/ortondotia.png'
import protese from '../assets/proteses.png'
import implante from '../assets/implantes.png'
import cirurgia from '../assets/cirurgia.png'
import clareamento from '../assets/clareamento.png'



const Estrategias = () => {
  

    return(
        <>
    <section className=' bg-preto pb-12  font-Bask'> 
    <div>
         <h2 className="text-prata text-5xl  text-center p-5 md:pt-8 ">
           Nossos Serviços
        </h2>
        
            
            <div className="flex flex-col sm:flex-row items-center justify-center">
                            
               <Servico
               imagem={clinica}
               titulo="Clinico Geral"
               texto="Realizamos limpezas, restaurações e diagnósticos completos, focando na prevenção e tratamento de doenças. Nossa equipe qualificada oferece atendimento personalizado, garantindo um sorriso saudável e radiante."
               />
                
                <Servico
               imagem={ortodon}
               titulo="Ortodontia"
               texto="Nossa ortodontia corrige desalinhamentos dentários com tratamentos personalizados para todas as idades. Nosso ortodontista, com mais de 10 anos de experiência, visa melhorar a estética e a saúde bucal. Garantimos um sorriso harmônico e funcional, sempre com conforto e eficiência."
               />

              <Servico
               imagem={protese}
               titulo="Próteses"
               texto="Oferecemos próteses dentárias de alta qualidade, desde próteses parciais até totais. Nossa equipe especializada assegura um encaixe perfeito e natural, proporcionando conforto e funcionalidade. Com atendimento personalizado, devolvemos a estética e a confiança ao seu sorriso."
               />                  
        
            </div>
   
            
            <div className="flex flex-col sm:flex-row items-center justify-center">
                            
            <Servico
               imagem={implante}
               titulo="Implantes"
               texto="Realizamos implantes dentários de alta qualidade, devolvendo a função e a estética do seu sorriso. Com técnicas modernas e uma equipe experiente, garantimos resultados duradouros e confortáveis."
               />
                
                <Servico
               imagem={cirurgia}
               titulo="Cirurgia"
               texto="Realizamos cirurgias odontológicas, incluindo extrações de terceiros molares, com segurança e precisão. Proporcionamos conforto e recuperação rápida, sempre priorizando o bem-estar e a saúde dos nossos pacientes."
               />

              <Servico
               imagem={clareamento}
               titulo="Clareamento"
               texto="Oferecemos clareamento dental seguro e eficaz, realçando o brilho natural do seu sorriso. Nossa equipe utiliza técnicas modernas para garantir resultados duradouros, proporcionando dentes mais brancos e uma aparência revitalizada."
               />
                    
                    
            </div>
       </div>
    </section >  
    </>
    )
}

export default Estrategias